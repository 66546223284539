import React, { Component } from 'react';
import CardProducto from './CardProducto';
import BasePathAsset from '../components/BasePathAssets';

/**
 * Contiene nuestra lista de productos que se muestra en la vista de Productos.js si es que hay productos
 * el array.map usa el componente de CardProducto
 */
class ListProductos extends Component {
    render() {
        return(
            <div className="row">
                {this.props.listado.map((item, key) => {
                    return(
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" key={key}>
                            <CardProducto
                                cover={BasePathAsset+item.cover}
                                title={item.titulo}
                                price={item.precio}
                                too={"/producto/"+item.id+"/detalle"}
                            />
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default ListProductos;