import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Este componente solicita props especificos para su funcionamiento
 */
const BlogCard = ({cover, title, text, too}) => {
    return (
        <div className="card card-blog border-0 rounded-0">
            <img src={cover} alt="Blog" className="img-object w-100" style={{height: "290px"}} />
            <div className="card-body">
                <h4 className="mb-3">{title}</h4>
                <p className="mb-4">{text}</p>
                <Link className="btn btn-style-2" to={too}>Leer más</Link>
            </div>
        </div>
    )
}

export default BlogCard;