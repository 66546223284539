import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Modal
 * Este componente es espacial, literalmente es un modal, {children} es un props de React que hara que podamos implementar un componente
 * en ese espacio un ejemplo basico seria
 * <Modal>
 *      Aqui va la informacion del modal, header, body y footer
 * </Modal>
 * En React se pueden usar componentes de Bootstrap usando la libreria React Bootstrap - https://react-bootstrap.github.io/
 * En este proyecto ya se usa bootstrap para no meter dos veces la libreria se decidio hacer nuestro propio modal usando 
 * las clases que trae bootstrap pero la funcionbalidad la hacemos nosotros, hacemos el codigo mas limpio.
 * 
 * Un ejemplo de como se usa mejor esta en ModalProducto.js
 */
const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? "modal d-block" : "modal";

    return (
        <div className={showHideClassName} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content rounded-0 border-0 py-4">
                    {children}
                    <button type="button" className="btn shadow-0 border-0 rounded-0 p-0 close-modal" onClick={handleClose}>
                        <FontAwesomeIcon icon={['fas', 'times']} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;