/**
 * Toda la informacion de API llega del proyecto DumedicsApp en git - https://bitbucket.org/lockeragency/dumedicsapp/src/main/
 * Es laravel 5.8
 * 
 * 
 * Importaciones de librearias de React
 * FontAwesome
 * Enrutamiento
 */
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

/**
 * Importacion de componentes de nuestra App
 * ScrollHandler - Componente que ayuda a React a que cuando se cambie de vista mediante JS se lleve al DOM hasta top 0
 * 
 * Vistas de nuestra App:
 * Header, Footer, Home, Nosotros (Somos dumedis, Somos Medder, etc), Noticias, Productos, Contacto y Aviso
 */
import ScrollHandler from './components/ScrollHandler';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import SomosDumedics from './pages/SomosDumedics';
import SomosMedder from './pages/SomosMedder';
import SomosWfb from './pages/SomosWfb';
import SomosAitem from './pages/SomosAitem';
import Noticias from './pages/Noticias';
import NoticiasDetalle from './pages/NoticiasDetalle';
import Productos from './pages/Productos';
import ProductoDetalle from './pages/ProductoDetalle';
import Contacto from './pages/Contacto';
import Aviso from './pages/Aviso';

library.add(fas, far, fab);

function App () {
	return(
		/**
		 * Iniciamos con el enrutamiento en React <Router>, <Switch> <Route>
		 * Doc: https://reactrouter.com/web/guides/quick-start
		 */
		<Router>
			<ScrollHandler />
			<Header />
			<Switch>
				<Route path="/" exact component={Home} />
				{/* Otra manera de hacer el enrutamiento es
					<Route path="home">
						<Home />
					</Route> */
				}
				<Route path="/home" component={Home} />
				<Route path="/nuestras-empresas/dumedics" component={SomosDumedics} />
				<Route path="/nuestras-empresas/medder" component={SomosMedder} />
				<Route path="/nuestras-empresas/wfb" component={SomosWfb} />
				<Route path="/nuestras-empresas/aitem" component={SomosAitem} />
				
				{/* Esta ruta obtiene parametros opciones que pueden o no estar :id? y :url? */}
				<Route path="/noticias/:id?/:url?" component={Noticias} />
				<Route path="/noticia/:id/detalle" component={NoticiasDetalle} />
				<Route path="/productos/:id/:subid/:url" component={Productos} />
				<Route path="/producto/:id/detalle" component={ProductoDetalle} />
				<Route path="/contacto" component={Contacto} />
				<Route path="/aviso-privacidad" component={Aviso} />
			</Switch>
			<Footer />
		</Router>
	);
}

export default App;