/**
 * GSAP - https://greensock.com/gsap/
 * Animacion con GSAP
 */
import {gsap} from 'gsap';

export function animationLS () {
    gsap.timeline({
        repeat: -1,
        yoyo: true
    })
    .to('.placeholder-opacity-scale', {
        opacity: 0.6,
        ease: 'power1.inOut',
        duration: 2,
        scale: 0.9
    })
    .to('.placeholder-opacity-scale', {
        opacity: 1,
        scale: 1,
        ease: 'power1.inOut',
        duration: 2,
    })
};

export function animationL () {
    gsap.timeline({
        repeat: -1,
        yoyo: true,
        duration: .5,
        ease: 'power3.inOut'
    })
    .to('.placeholder-opacity', {
        backgroundColor: "#dddddd"
    })
    .to('.placeholder-opacity', {
        backgroundColor: "#ebebeb"
    })
};