import React, { Component } from 'react';
/**
 * Importamos componentes que necesita a nuestra componente
 */
import LinkLoading from './LinkLoading';
import API from './Api';
class HeaderProdctosCategorias extends Component {
    // Aqui establecemos nuestros props locales
    constructor(props) {
        super(props);
        this.state = {
            categorias: [], //Array de categorias dinamicas
            activo: '',
            loading: true
        };
    }

    /**
     * Con el componentDidMount ejecutamos acciones que queremos que se ejecuten despues del renderizado de la vista
     * algo asi como un DOMLoadedContent
     */
    componentDidMount() {
        /**
         * Funcion que hace un fetch y obtiene la informacion de la API
         */
        API.get('menu-productos')
        .then(res => {
            let resultados = res.data;
            let activo = 'submenu-'+resultados[0].id;
            
            // Asignamos la respuesta a nuestros props
            this.setState({
                categorias: resultados,
                activo: activo,
                loading: false
            });
        });
    }

    /**
     * Funcion que navega entre los diferentes submenus que hay activando las nuevas listas se subcategorias
     */
    changeActivo = (act) => {
        this.setState({activo: 'submenu-'+act})
        console.log(this.state.activo);
    }

    render(){
        const handleClass = this.props.show ? 'sub-menu d-block' : 'sub-menu d-none';

        return(
            <div className={handleClass}>
                <div className="container">
                    <div className="row">
                        <div className="col-4 col-xl-3 py-4 border-r">
                            <ul className="list-unstyled mb-0 p-0">
                                {this.state.loading ? <LinkLoading clase={"placeholder-linkMenu"} /> : this.state.categorias.map((categoria, key) => <li className={`mb-4 pointerC ${this.state.activo === 'submenu-'+categoria.id ? 'active' : ''}`} key={key} onClick={() => this.changeActivo(categoria.id)}>{categoria.titulo}</li>)}
                            </ul>
                        </div>
                        <div className="col-8 col-xl-9 py-4 pl-5">
                                {this.state.loading ? <ul className="list-unstyled"><LinkLoading clase={"placeholder-linkSubMenu"} /></ul> :
                                    this.state.categorias.map((categoria, key) => {
                                        return (
                                            <ul className={`list-unstyled mb-0 p-0 list-sub-menu ${this.state.activo === 'submenu-'+categoria.id ? '' : 'd-none'}`} key={key}>
                                                {categoria.subcategorias.map((sub, idx) => {
                                                    console.log(sub);
                                                    return (
                                                        <li className="mb-1" key={idx}><a onClick={this.props.handleClose} href={`/productos/${categoria.id}/${sub.id}/${sub.urlAmigable}`}>{sub.titulo}</a></li>
                                                    )
                                                })}
                                            </ul>
                                        )
                                    })
                                }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HeaderProdctosCategorias;