import React, { useState } from 'react';
/**
 * Swiper en su version 6.8, para su version 7 esto cambia radicalmente
 */
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Thumbs } from 'swiper/core';
import BasePathAsset from '../components/BasePathAssets';

SwiperCore.use([Thumbs]);

/**
 * Se pueden declarar variables para estilos, para hacer responsivo se recomienda usar un .css
 */
var swiperSlideThumb = {
    height: "100px",
    objectFit: "contain",
};

/**
 * Este componente solicita props especificos para su funcionamiento
 * Este componente usa Hooks de React
 * useState - https://es.reactjs.org/docs/hooks-state.html
 */
const Galeria = ({gallery, loading, portada}) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return(
        <>
            {
                /**
                 * Si la galeria solo es una imagen usa un tag img y no el plugin
                 */
                gallery.length === 0 ?
                <img src={BasePathAsset+portada} alt="Portada" className="w-100" />
                :
                /**
                 * En caso contrario usa el plugin para hacer la galeria con thumbs
                 */
                <>
                    {/* Galeria */}
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        navigation
                        thumbs={{ swiper: thumbsSwiper }}
                        className="mb-3"
                    >
                        {gallery.map((photo, key) => {
                            return(
                                <SwiperSlide key={`slide-${key}`}>
                                    {
                                        loading ?
                                        <div className="bg bg-size-auto" style={{backgroundImage: `url(${photo})`}}>
                                            <img src={photo} alt="Galeria" className="img-object w-100 ssm" />
                                        </div>
                                        :
                                        <img src={BasePathAsset+photo.cover} alt="Galeria" className="img-object w-100 ssm" />
                                    }
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>

                    {/* Thumbs */}
                    <Swiper
                        onSwiper={setThumbsSwiper}
                        watchSlidesVisibility
                        watchSlidesProgress
                        spaceBetween={10}
                        slidesPerView={5}
                        freeMode={true}
                        breakpoints={{
                            320: {
                                slidesPerView: 4
                            },
                            992: {
                                slidesPerView: 5
                            },
                        }}
                    >
                        {gallery.map((photo, key) => {
                            return(
                                <SwiperSlide key={`thumbs-${key}`} className="sst">
                                    {
                                        loading ?
                                        <div className="bg bg-size-auto" style={{backgroundImage: `url(${photo})`}}>
                                            <img src={photo} alt="Galeria" className="img-object w-100" style={swiperSlideThumb} />
                                        </div>
                                        :
                                        <img src={BasePathAsset+photo.cover} alt="Galeria" className="img-object w-100" style={swiperSlideThumb} />
                                    }
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </>
            }
        </>
    );
}

export default Galeria;