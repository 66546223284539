import React, { Component } from 'react';
import BannerBlue from '../components/BannerBlue';
import { Link } from 'react-router-dom';

import '../sass/pages/nuestras_empresas.scss';

import logo from '../img/medder/logo.png';
import bgbanner from '../img/medder/medder_cover.jpg';
import imagen_1 from '../img/medder/medder_ventajas.jpg';
import imagen_2 from '../img/medder/medder_nueva.jpg';

class SomosMedder extends Component {
    render() {
        return(
            <div className="somos">
                {/* Portada azul */}
                <BannerBlue
                    logo={true}
                    title={"Medder"}
                    img={logo}
                    description={"Medder es una plataforma de expediente electrónico, pero también es un directorio medico, ¡SI! Medder es una plataforma integral que une médicos y pacientes. A través de Medder los pacientes pueden encontrar al médico que están buscando y los médicos y personal de salud pueden dar consultas y tener toda la información de sus pacientes a un solo click"}
                    bgimg={bgbanner}
                />

                {/* Ventajas */}
                <div className="somos-section-4">
                    <div className="container-fluid w12">
                        <div className="row bg-gris">
                            <div className="col-12 col-md-6 pr-lg-4 d-flex align-items-start justify-content-center py-5 pl-lg-5" style={{flexDirection: "column"}}>
                                <h3 className="titulo mb-3">¿Qué ventajas ofrece? </h3>
                                <p>Agilidad y practicidad, olvídese de las llamadas telefónicas para obtener una cita, ahora podrá hacerlo desde el Directorio de Medder donde se reúnen los médicos y especialistas de su zona.</p>
                                <p>Para los médicos, Medder ofrece agilidad en una consulta, procurando la relación médico paciente disminuyendo el tiempo en pantalla y aumentando el contacto visual.</p>
                            </div>
                            <div className="col-12 col-md-6 px-0">
                                <img src={imagen_1} alt="Imagen" className="h-100 w-100 img-object" style={{minHeight: "300px"}} />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Informacion */}
                <div className="somos-section-2">
                    <div className="container-fluid w12">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6 mb-4 mb-md-0">
                                <img src={imagen_2} alt="Imagen" className="max-width-100" />
                            </div>
                            <div className="col-12 col-md-6 pl-lg-4">
                                <h3 className="titulo mb-3">¿Qué hace a Medder diferente? </h3>
                                <p>Se han visto plataformas para encontrar médicos y revisar su perfil, por otro lado, hay plataformas que ofrecen servicio de expediente electrónico o administración del negocio de salud. </p>
                                <p>Pero Medder engloba a pacientes y especialistas en una misma plataforma donde ambas partes pueden interactuar lo que favorece altamente a la relación médico paciente y a que estos últimos se sientan mas motivados a seguir sus tratamientos. </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Informacion 2 */}
                <div className="somos-section-3 py-5 text-center text-white">
                    <h5>Visita nuestra plataforma y adquiere tu periodo de prueba gratuito por 7 meses</h5>
                    <h5>
                    <a href="http://www.medder.com.mx" target="_blank" rel="noreferrer">
                    www.medder.com.mx
                        </a></h5>
                </div>
            </div>
        )
    }
}

export default SomosMedder;