import React, { Component } from 'react';
import Tabs from '../components/Tabs';
import FormContacto from '../components/FormContacto';

import '../sass/pages/contacto.scss';

import logo_dumedics from '../img/contacto/logo-dumedics.svg';
import logo_aitem from '../img/contacto/logo-aitem.png';
import logo_wfb from '../img/contacto/logo-mfb.svg';
import logo_medder from '../img/contacto/logo-medder.png';
import bg_dumedics from '../img/contacto/Contacto_bg_Form.jpg';
import bg_aitem from '../img/contacto/Contacto_bg_Form.jpg';
import bg_wfb from '../img/contacto/Contacto_bg_Form.jpg';
import bg_medder from '../img/contacto/Contacto_bg_Form.jpg';

class Contacto extends Component {
    render(){
        return(
            <div className="contacto">
                {/* Informacion */}
                <div className="contacto-informacion">
                    <div className="container-fluid w14">
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-4 mb-4 mb-md-0">
                                <h3 className="titulo mb-0">Ponte en contacto con nosotros</h3>
                            </div>
                            {/* <div className="col-12 col-md-7 col-lg-8" >
                                <p>Nam metus diam, commodo et erat sed, aliquet ultricies lorem. Nulla molestie molestie tempus. Quisque tempus luctus magna, et faucibus elit varius nec. Etiam tincidunt fringilla dictum. Mauris libero nisl, pretium maximus mollis eget, aliquet quis eros.</p>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* Tabs formularios */}
                <div className="contacto-formularios">
                    <div className="container-fluid w14 p-0">
                        <Tabs>
                            <div label="Grupo Dumedics">
                                <div className="row no-gutters mb-5">
                                    <div className="col-12 col-md-6 py-4 px-3">
                                        <p className="mb-2"><a href="mailto:contacto@grupodumedics.com.mx" ref="noreferrer noopener">contacto@grupodumedics.com.mx</a></p>
                                        <p className="mb-2"><a href="tel:+529991850970 " ref="noreferrer noopener">(999) 185 0970</a></p>
                                        {/* <p className="mb-0">Dirección lorem ipsum dolo sit amet pratas lhadala.</p> */}
                                    </div>
                                    <div className="col-12 col-md-6 text-center pt-md-4 pb-md-4 px-3">
                                        <img src={logo_dumedics} alt="Grupo Ducmedics" className="max-width-100"/>
                                    </div>
                                </div>

                                <FormContacto bg={bg_dumedics} empresa={'Grupo Dumedics'} />
                            </div>
                            <div label="AITEM México">
                                <div className="row no-gutters mb-5">
                                    <div className="col-12 col-md-6 py-4 px-3">
                                        <p className="mb-2"><a href="mailto:info@aitemmexico.com" ref="noreferrer noopener">info@aitemmexico.com</a></p>
                                        <p className="mb-2"><a href="tel:+529991850970 " ref="noreferrer noopener">(999) 185 0970</a></p>
                                        {/* <p className="mb-0">Dirección lorem ipsum dolo sit amet pratas lhadala.</p> */}
                                    </div>
                                    <div className="col-12 col-md-6 text-center pt-md-4 pb-md-4 px-3">
                                        <img src={logo_aitem} alt="AITEM" className="max-width-100"/>
                                    </div>
                                </div>

                                <FormContacto bg={bg_aitem} empresa={'AITEM'} />
                            </div>
                            <div label="WFB">
                                <div className="row no-gutters mb-5">
                                    <div className="col-12 col-md-6 py-4 px-3">
                                        <p className="mb-2"><a href="mailto:info@aitemmexico.com" ref="noreferrer noopener">info@aitem.com.mx</a></p>
                                        <p className="mb-2"><a href="tel:+529991850970 " ref="noreferrer noopener">(999) 185 0970</a></p>
                                        {/* <p className="mb-0">Dirección lorem ipsum dolo sit amet pratas lhadala.</p> */}
                                    </div>
                                    <div className="col-12 col-md-6 text-center pt-md-4 pb-md-4 px-3">
                                        <img src={logo_wfb} alt="WFB" className="max-width-100"/>
                                    </div>
                                </div>

                                <FormContacto bg={bg_wfb} empresa={'WFB'} />
                            </div>
                            <div label="Medder">
                                <div className="row no-gutters mb-5">
                                    <div className="col-12 col-md-6 py-4 px-3">
                                        <p className="mb-2"><a href="mailto:contacto@medder.com.mx" ref="noreferrer noopener">contacto@medder.com.mx</a></p>
                                        <p className="mb-2"><a href="tel:+529991850970 " ref="noreferrer noopener">(999) 185 0970</a></p>
                                        {/* <p className="mb-0">Dirección lorem ipsum dolo sit amet pratas lhadala.</p> */}
                                    </div>
                                    <div className="col-12 col-md-6 text-center pt-md-4 pb-md-4 px-3">
                                        <img src={logo_medder} alt="Medder" className="max-width-100"/>
                                    </div>
                                </div>

                                <FormContacto bg={bg_medder} empresa={'Medder'} />
                            </div>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contacto;