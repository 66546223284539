import React from 'react';

/**
 * Este componente solicita props especificos para su funcionamiento
 */
const CardProducto = ({cover, title, price, too}) => {
    return(
        <div className="card card-producto h-100">
            <img src={cover} alt="Producto" className="img-object" />
            <div className="card-body text-center">
                <h4 className="mb-0">{title}</h4>
                <h4 className="font-weight-bold">$ {price}</h4>
                <a href={too} className="btn btn-style-2">Ver mas</a>
            </div>
        </div>
    );
}

export default CardProducto;