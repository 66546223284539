
import React, { Component } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Scrollbar } from 'swiper'
import 'swiper/components/pagination/pagination.scss';
/**
 * Link equivale a <a href=""></a>
 * Extension de react-router-dom - documentacion en App.js
 * */
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Importamos componentes referentes a nuestra vista
 */
import NuestrasEmpresas from '../components/NuestrasEmpresas';
import BlogCard from '../components/BlogCard';

/**
 * Importamos estilos pueden ser de SCSS, SASS o CSS
 */
import 'swiper/swiper.scss';
import '../sass/pages/home.scss';


/**
 * Todas las imagenes se tienen que importar como componentes para poder usarse
 */
import portada from '../img/home/img_portada_new.jpg';
import portada2 from '../img/home/Slide_01.jpg';

import slider1 from '../img/home/img_portada_new.jpg';
import slider2 from '../img/home/Slide_01.jpg';
import slider3 from '../img/home/Slide_03.jpg';

import somos from '../img/home/quienes_somo.jpg';
import somos2 from '../img/home/imagen3.jpg';
import informacion3 from '../img/home/04_imagen.jpg';
import nota1 from '../img/home/notas/nota1.jpg';
import nota2 from '../img/home/notas/nota2.jpg';
import nota3 from '../img/home/notas/nota3.jpg';
import bgContacto from '../img/home/img_footer.jpg';

SwiperCore.use([Autoplay, Pagination, Scrollbar])

class Home extends Component {
    render() {
        return (
            <div className="home">
                {/* Portada */}
                <div className="home-baner">
                    <div className="container-fluid w16 px-0">
                    <Swiper
                    modules={[ Pagination, Scrollbar, Autoplay]}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    autoplay={{ delay: 3000 }}
                    autoHeight
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log('slide change')}
                    className="mt-5 mb-3"
                    >
                        <SwiperSlide><img src={slider2} alt="Slider" style={{width:"100%"}}></img></SwiperSlide>
                        <SwiperSlide><img src={slider1} alt="Slider" style={{width:"100%"}}></img></SwiperSlide>
                        <SwiperSlide><img src={slider3} alt="Slider" style={{width:"100%"}}></img></SwiperSlide>
                    </Swiper>
                    {/* <Galeria gallery={'1'} loading={" "} portada={portada} /> */}
                        {/* <img src={portada} alt="Portada" className="max-width-100" /> */}
                    </div>
                </div>

                {/* Somos - informacion 1 */}
                <div className="home-somos" id={"quienes-somos"}>
                    <div className="container-fluid w12">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6 mb-5 mb-md-0">
                                <img src={somos} alt="¿Quienes somos?" className="img-object" />
                            </div>
                            <div className="col-12 col-md-6 pl-lg-5">
                                <h3 className="titulo mb-4">¿Quiénes somos?</h3>
                                <p>Grupo Empresarial Dumedics es un conjunto de empresas de la industria de la salud enfocadas en simplificar la labor de los profesionales sanitarios ofreciendo soluciones practicas e innovadoras a travez de nuestros productos, a la vez que buscamos mejorar la experiencia del paciente recibiendo una atención medica de calidad.</p>
                                <p className="mb-0">Tenemos la responsabilidad de entregar a los profesionales e instituciones de salud productos y servicios adaptados a las necesidades de cada cliente y brindando siempre la atención que nos destaca.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Somos - informacion 2 */}
                <div className="home-somos2">
                    <div className="container-fluid w12">
                        <div className="row align-items-center square">
                            <div className="col-12 col-md-6 pr-lg-5 mb-5 mb-md-0">
                                <p className="mb-0 mb-md-5 pb-md-5">Nuestra empresa nace de la fusion de dos startups en la industria de la salud las cuales toman la decision de unificarse para tomar fuerza creando un sociedad, “Grupo Empresarial Dumedics” con el cual buscamos potenciar los servicios de salud en el país a travez de productos y servicios innovadores y con una visión hacia al futuro.</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <img src={somos2} alt="¿Quienes somos?" className="img-object" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Empresas */}
                <NuestrasEmpresas />

                {/* Informacion 3 */}
                <div className="home-informacion-3 ocultar">
                    <div className="container-fluid w12 text-center">
                        <h3 className="titulo text-white mb-5">Lorem ipsum dolor sit amet, consectetur.</h3>
                        <p className="text-white mx-auto mb-5">Integer mattis nibh ac neque finibus semper. Sed dignissim porttitor luctus. Aenean imperdiet congue ligula eget viverra. Nunc at ultricies ligula.Integer mattis nibh ac neque finibus semper. Sed dignissim porttitor luctus. Aenean imperdiet congue ligula eget viverra. Nunc at ultricies ligula.Integer mattis nibh ac neque finibus semper. Sed dignissim porttitor luctus.</p>
                        <img src={informacion3} alt="imagen 3" className="max-width-100" />
                    </div>
                </div>

                {/* Ultimas Noticias */}
                <div className="home-ultimas-noticias d-none">
                    <div className="container-fluid w12">
                        <div className="row">
                            <div className="col-7">
                                <h3 className="titulo">Últimas noticias</h3>
                                <hr />
                            </div>
                            <div className="col-5 text-right">
                                <Link className="btn btn-style-1" to="/blog">Ver más</Link>
                            </div>
                            <div className="col-12 col-md-4 mb-3">
                                {
                                    /**
                                     * Este componente es un Card de Noticias y este a su vez recibe sus props (parametros)
                                     * cover - Portada del card
                                     * title - Titulo de la noticia
                                     * text - Descripcion corta de la noticia
                                     * too - El enrutamiento
                                     * 
                                     * Los props son custom y pueden agregar mas de ser necesario, al hacer cambios en el componente, todos los
                                     * sitios donde se use nuestro componente tambien tendran ese cambio
                                     */
                                }
                                <BlogCard cover={nota1} title={"Lorem ipsum dolor"} text={"Integer mattis nibh ac neque finibus semper. Sed dignissim porttitor luctus."} too={"/blog/detalle"} />
                            </div>
                            <div className="col-12 col-md-4 mb-3">
                                <BlogCard cover={nota2} title={"Lorem ipsum dolor"} text={"Integer mattis nibh ac neque finibus semper. Sed dignissim porttitor luctus."} too={"/blog/detalle"} />
                            </div>
                            <div className="col-12 col-md-4 mb-3">
                                <BlogCard cover={nota3} title={"Lorem ipsum dolor"} text={"Integer mattis nibh ac neque finibus semper. Sed dignissim porttitor luctus."} too={"/blog/detalle"} />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Contacto */}
                {
                    /**
                     * Los estilos se establecen mediante JS
                     * Normalmente hariamos
                     * style="background-image: url(imagenUrl)"
                     * en React esto seria
                     * style={{backgroundImage: `url(${imagenUrl})`}}
                     */
                }
                <div className="home-contactanos" style={{backgroundImage: `url(${bgContacto})`}}>
                    <div className="container-fluid w12">
                        <h3 className="titulo text-white">Ponte en contacto con nosotros</h3>
                        <hr className="ml-0 mb-5" />

                        <div className="row">
                            <div className="col-12 col-md-6 col-xl-3 mb-4">
                                <h4 className="tlo">Grupo Dumedics</h4>
                                <p className="d-flex align-items-start w-100 text-break"><FontAwesomeIcon className="mt-1 mr-2" icon={['far', 'envelope']} /> contacto@grupodumedics.com.mx</p>
                                <p className="d-flex align-items-start w-100 text-break"><FontAwesomeIcon className="mt-1 mr-2" icon={['fas', 'phone-alt']} /> (999) 185 0970 </p>
                                {/* <p className="d-flex align-items-start w-100 text-break"><FontAwesomeIcon className="mt-1 mr-2" icon={['fas', 'map-marker-alt']} /> Dirección lorem ipsum dolo sit amet pratas lhadala.</p> */}
                                <Link className="btn btn-style-3" to="/contacto">Contactar</Link>
                            </div>
                            <div className="col-12 col-md-6 col-xl-3 mb-4">
                                <h4 className="tlo">Aitem</h4>
                                <p className="d-flex align-items-start w-100 text-break"><FontAwesomeIcon className="mt-1 mr-2" icon={['far', 'envelope']} /> info@aitemmexico.com</p>
                                <p className="d-flex align-items-start w-100 text-break"><FontAwesomeIcon className="mt-1 mr-2" icon={['fas', 'phone-alt']} /> (999) 185 0970 </p>
                                {/* <p className="d-flex align-items-start w-100 text-break"><FontAwesomeIcon className="mt-1 mr-2" icon={['fas', 'map-marker-alt']} /> Dirección lorem ipsum dolo sit amet pratas lhadala.</p> */}
                                <Link className="btn btn-style-3" to="/contacto">Contactar</Link>
                            </div>
                            <div className="col-12 col-md-6 col-xl-3 mb-4">
                                <h4 className="tlo">WFB</h4>
                                <p className="d-flex align-items-start w-100 text-break"><FontAwesomeIcon className="mt-1 mr-2" icon={['far', 'envelope']} /> info@aitemmexico.com</p>
                                <p className="d-flex align-items-start w-100 text-break"><FontAwesomeIcon className="mt-1 mr-2" icon={['fas', 'phone-alt']} /> (999) 185 0970 </p>
                                {/* <p className="d-flex align-items-start w-100 text-break"><FontAwesomeIcon className="mt-1 mr-2" icon={['fas', 'map-marker-alt']} /> Dirección lorem ipsum dolo sit amet pratas lhadala.</p> */}
                                <Link className="btn btn-style-3" to="/contacto">Contactar</Link>
                            </div>
                            <div className="col-12 col-md-6 col-xl-3 mb-4">
                                <h4 className="tlo">Medder</h4>
                                <p className="d-flex align-items-start w-100 text-break"><FontAwesomeIcon className="mt-1 mr-2" icon={['far', 'envelope']} /> contacto@medder.com.mx</p>
                                <p className="d-flex align-items-start w-100 text-break"><FontAwesomeIcon className="mt-1 mr-2" icon={['fas', 'phone-alt']} /> (999) 185 0970 </p>
                                {/* <p className="d-flex align-items-start w-100 text-break"><FontAwesomeIcon className="mt-1 mr-2" icon={['fas', 'map-marker-alt']} /> Dirección lorem ipsum dolo sit amet pratas lhadala.</p> */}
                                <Link className="btn btn-style-3" to="/contacto">Contactar</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home;