import React, { Component } from 'react';
import BannerBlue from '../components/BannerBlue';
import NuestrasEmpresas from '../components/NuestrasEmpresas';

import '../sass/pages/nuestras_empresas.scss';

import bgbanner from '../img/dumedics/Dumedics_bg.jpg';
import imagen_1 from '../img/dumedics/primera.jpg';
import imagen_2 from '../img/dumedics/segunda.jpg';
import imagen_3 from '../img/dumedics/03.jpg';
import imagen_4 from '../img/dumedics/cuarta.jpg';
import icon1 from '../img/dumedics/icon_01.svg';
import icon2 from '../img/dumedics/icon_02.svg';
import icon3 from '../img/dumedics/icon_03.svg';
import icon4 from '../img/dumedics/icon_04.svg';
import icon5 from '../img/dumedics/icon_05.svg';
import icon6 from '../img/dumedics/icon_06.svg';

class SomosDumedics extends Component {
    render(){
        /**
         * 
         * @param {*} props Parametros heredados a nuestro componente desde otro componente
         * @returns Componente
         */
        function ValorEmpresa(props) {
            return(
                <div className="col-12 col-md-6 d-flex align-items-start mb-4">
                    <img src={props.icon} alt={props.title} className="max-width-100 mr-4" />
                    <div className="cont-text">
                        <h6 className="text-white mb-2">{props.title}</h6>
                        <p className="text-white">{props.text}</p>
                    </div>
                </div>
            )
        }

        return (
            <div className="somos">
                {/* Portada azul */}
                <BannerBlue
                    logo={false}
                    title={"Acerca de Grupo Dumedics"}
                    img={''}
                    description={"Acercate a nosotros, tenemos la solución que has estado buscando, y si no la tenemos, la creamos para ti, porque en Grupo Dumedics buscamos la mejor manera de adaptar nuestros productos y servicios a tus necesidades y no basta entregar un producto o cumplir un proyecto sino que junto contigo creamos una alianza, una familia… Dumedics."}
                    bgimg={bgbanner}
                />

                {/* Mision */}
                <div className="somos-section-1">
                    <div className="container-fluid w12">
                        <div className="row align-items-center square">
                            <div className="col-12 col-md-6 mb-5 mb-md-0">
                                <img src={imagen_1} alt="¿Quienes somos?" className="max-width-100" />
                            </div>
                            <div className="col-12 col-md-6 pl-lg-5">
                                <h3 className="titulo mb-3">Misión</h3>
                                <p className="mb-0 mb-md-5 pb-md-5">Ser proveedor de soluciones integrales para clinicas, hospitales, médicos y empresas de salud que busquen la excelencia en sus servicios, herramientas de trabajo y atencion del paciente.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Vision */}
                <div className="somos-section-2">
                    <div className="container-fluid w14">
                        <div className="row align-items-center justify-content-end">
                            <div className="col-12 col-md-6 col-lg-5 mb-4 mb-md-0">
                                <h3 className="titulo mb-3">Visión</h3>
                                <p>Ser siempre la primera opcion para clientes pequeños y grandes que buscan calidad de atención de proveedores y agilidad en la resolución de problemas.</p>
                            </div>
                            <div className="col-12 col-md-6 d-flex align-items-center">
                                <img src={imagen_2} alt="imagen" className="col-6" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Valores */}
                <div className="somos-section-3">
                    <div className="container-fluid w16">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <img src={imagen_4} alt="imagen" className="h-100 img-object w-100" style={{minHeight: "300px"}} />
                            </div>
                            <div className="col-12 col-lg-6 py-5">
                                <h3 className="titulo text-white mb-5">Valores de la empresa</h3>

                                <div className="row">
                                    <ValorEmpresa icon={icon1} title={"Transparencia"} text={"Hacia nuestros colaboradores, tanto clientes como equipo de trabajo."} />
                                    <ValorEmpresa icon={icon2} title={"Responsabilidad"} text={"Tomamos acción en forma anticipada siempre pensando en brindar apoyo."} />
                                    <ValorEmpresa icon={icon3} title={"Diferencia"} text={"Servicio de calidad y atención al cliente como si fuese único."} />
                                    <ValorEmpresa icon={icon4} title={"Escucha"} text={"Siempre atentos a las necesidades de cada cliente."} />
                                    <ValorEmpresa icon={icon5} title={"Excelencia"} text={"La calidad al máximo tanto en nuestros productos como nuestro equipo de trabajo."} />
                                    <ValorEmpresa icon={icon6} title={"Compromiso"} text={"Estamos ahí cuando usted lo necesita para brindarle siempre servicio de calidad."} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Nuestras empresas */}
                <NuestrasEmpresas clase="dumedics" />
            </div>
        )
    }
}

export default SomosDumedics;