import React, { Component } from 'react';
import {animationL} from './LoadingAnimation';

import '../sass/pages/loading.scss';

class TextLoading extends Component{
    htmlLinkLoading() {
        return <div className={`placeholder-opacity ${this.props.clase}`} style={{height: this.props.height, width: this.props.width}}></div>
    }

    componentDidMount() {
        animationL();
    }

    render() {
        return(
            <>
                {this.htmlLinkLoading()}
            </>
        )
    }
}

export default TextLoading;