import React, { Component } from 'react';
import BlogCard from '../components/BlogCard';

/**
 * Importamos componentes referentes a nuestra vista
 */
import API from '../components/Api';
import CardLoading from '../components/CardLoading';
import BasePathAsset from '../components/BasePathAssets';
import TextLoading from '../components/TextLoading';
import SinResultados from '../components/SinResultados';

/**
 * Importamos estilos pueden ser de SCSS, SASS o CSS
 */
import '../sass/pages/noticias.scss';

/**
 * Todas las imagenes se tienen que importar como componentes para poder usarse
 */
import nota1 from '../img/home/notas/nota1.jpg';

class Noticias extends Component {
    // Aqui establecemos nuestros props locales
    constructor(props) {
        super(props);
        this.state = {
            pagina: 1, // Iniciamos nuestra pagina en 1 para cargar los datos
            loading: true, // En lo que llegan los datos el estado es cargando
            categorias: [], //Array donde guardaremos nuestras categorias
            noticias: [], // Array donde guardaremos nuestras noticias
            totalDatos: 0 //Con esto revisamos si el total de notas es igual al tamaño del Array para ocultar el boton de Ver mas
        }

        this.masNotas = this.masNotas.bind(this);
    }

    /**
     * Funcion que hace un fetch y obtiene la informacion de la API
     */
    obtenerDatos() {
        // Asiganmos nuestros resultados a nuestros props
        this.setState({pagina: this.state.pagina + 1});
        let data = {
            categoria_id: this.props.match.params.id,
            page: this.state.pagina
        }
        API.get('noticias', {params: data})
        .then(res => {
            // Asignamos la respuesta a nuestros props
            this.setState({
                categorias: res.data.categorias,
                noticias: [...this.state.noticias, ...res.data.noticias.data],
                loading: false, //Como la info ya esta seteada el estado de cargando pasa a false
                totalDatos: res.data.noticias.data.length
            });
        })
    }

    /**
     * Con el componentDidMount ejecutamos acciones que queremos que se ejecuten despues del renderizado de la vista
     * algo asi como un DOMLoadedContent
     */
    componentDidMount() {
        // Ejecutamos la obtencion de datos
        this.obtenerDatos();
    }

    /**
     * Funcion que carga mas notas en la vista, inicialmente carga 6 (creo) el ver mas cargara otras 6 y asi sucesivamente 
     * hasta que se cargen todas las notas de la BD
     */
    masNotas() {
        this.obtenerDatos();
    }

    // Renderizamos nuestra vista
    render() {
        return (
            <div className="noticias">
                <div className="container-fluid w14">
                    {/* Categorias y buscador */}
                    <div className="row justify-content-between mb-5">
                        <div className="col-12 col-md-6 mb-4">
                            <h3 className="titulo mb-0">Noticias</h3>
                        </div>
                        <div className="col-12 col-md-6 col-lg-5 col-xl-4 mb-4">
                            <div className="form-group">
                                {/* <input type="search" className="form-control" placeholder="Buscar" /> */}
                            </div>
                        </div>
                        <div className="col-12">
                            <ul className="list-unstyled d-md-flex align-items-center">
                                {
                                    /**
                                     * Condicional ternario - https://developer.mozilla.org/es/docs/Web/JavaScript/Reference/Operators/Conditional_Operator
                                     * Si el estado esta en cargando no mostrara nada pero si es falso mostrara la categoria "Todos"
                                     */
                                    this.state.loading ? '' : <li className="mr-md-3 mr-xl-5"><a href="/noticias">Todos</a></li>
                                }
                                {
                                    /**
                                     * Si el estado cargando es verdadero mostrara un placeholder de texto cargando similiar al de Youtube
                                     * Array.map - https://developer.mozilla.org/es/docs/Web/JavaScript/Reference/Global_Objects/Array/map
                                     * React no ejecuta array.forEach en vez de esto se usa array.map
                                     * Si el estado cargando es falso hara un array.map de la informacion obtenida si es que tiene informacion
                                     */
                                    this.state.loading ?
                                    <>
                                        <li className="mr-md-3 mr-xl-5"><TextLoading clase={"placeholder-text"} height={"15px"} width={"100px"} /></li>
                                        <li className="mr-md-3 mr-xl-5"><TextLoading clase={"placeholder-text"} height={"15px"} width={"100px"} /></li>
                                        <li className="mr-md-3 mr-xl-5"><TextLoading clase={"placeholder-text"} height={"15px"} width={"100px"} /></li>
                                        <li className="mr-md-3 mr-xl-5"><TextLoading clase={"placeholder-text"} height={"15px"} width={"100px"} /></li>
                                    </>
                                    :
                                    /**
                                     * Recorremos nuestro array props de categorias usando array.map
                                     */
                                    this.state.categorias.map((item, key) => {
                                        /**
                                         * Cuando se hace un array.map se debe retornar el resultado en este caso el componente a mostrar que es <li> > <a>
                                         */
                                        return(
                                            <li className="mr-md-3 mr-xl-5" key={`ct${key}`}>
                                                <a href={`/noticias/${item.id}/${item.urlAmigable}`}>{item.titulo}</a>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                    </div>

                    {/* Notas */}
                    <div className="row">
                        {
                            /**
                             * Condicional ternario anidado
                             * Si el estado cargando es verdadero mostrara un placeholder de los cards similiar al de Youtube
                             * Si es falso hara el recorrdio del noticias props usando array.map
                             * Pero si no hay imformacion se mostrara el compente que avisa que no hay resultados, esto tambien aplica para el buscador
                             */
                            this.state.loading ?
                            <>
                                <CardLoading clase={"col-12 col-md-4 mb-4 nomax"} />
                                <CardLoading clase={"col-12 col-md-4 mb-4 nomax"} />
                                <CardLoading clase={"col-12 col-md-4 mb-4 nomax"} />
                                <CardLoading clase={"col-12 col-md-4 mb-4 nomax"} />
                                <CardLoading clase={"col-12 col-md-4 mb-4 nomax"} />
                                <CardLoading clase={"col-12 col-md-4 mb-4 nomax"} />
                            </>
                            :
                            (this.state.noticias.length === 0) ?
                            <SinResultados />
                            :
                            this.state.noticias.map((item, key) => {
                                return(
                                    /**
                                     * El atributo key es un id especial de React que se usa en recorridos para decir que es un identificador unico
                                     * nt - notas
                                     * key - indice de nuestro array
                                     */
                                    <div className="col-12 col-md-4 mb-4" key={`nt${key}`}>
                                        <BlogCard
                                            cover={BasePathAsset+item.portada}
                                            title={item.titulo}
                                            text={item.descripcion_corta}
                                            too={`/noticia/${item.id}/detalle`}
                                        />
                                    </div>
                                );
                            })
                        }
                    </div>

                    {
                        /**
                         * Si el total de los datos es igual al numero de cards que mostramos entonces significa que hay mas que mostrar
                         * si al presionar no llegan mas resultados o llega un tamaño menos significa que ya no habra mas resultados que solicitar
                         * por lo tanto el boton se oculta
                         */
                        this.state.totalDatos === 6 ?
                        <div className="text-center mt-5 pt-4">
                            <button className="btn btn-style-2" onClick={this.masNotas}>Ver más</button>
                        </div>
                        :
                        ''
                    }
                </div>
            </div>
        )
    }
}

export default Noticias;