import React from 'react';

/**
 * Este componente solicita props especificos para su funcionamiento
 */
const BannerBlue = ({logo, title, img, description, bgimg, small}) => {

    /**
     * Funcion que valida si pasamos un logo o un titulo de texto
     * @returns Componente
     */
    function TextOrLogo() {
        /**
         * Si hay un logo se retorna un tag img
         */
        if (logo) {
          return (<img src={img} alt={title} className="max-width-100 mb-4" />);
        }

        /**
         * Si no retorna un tag h3 con el titulo
         */
        return (<h3 className="titulo text-white text-center mb-4">{title}</h3>);
    }

    return (
        <div className={`banner-blue d-flex align-items-center justify-content-center py-4 ${small}`} style={{backgroundImage: `url(${bgimg})`}}>
            <div className="cont text-center">
                <TextOrLogo />
                <p className="text-white mx-auto text-center mb-0 col-12 col-md-8 col-lg-6">{description}</p>
            </div>
        </div>
    );
}

export default BannerBlue;