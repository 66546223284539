import React, { Component } from 'react';
import BlogCard from '../components/BlogCard';
import API from '../components/Api';
import TextLoading from '../components/TextLoading';
import CardLoading from '../components/CardLoading';
import BasePathAsset from '../components/BasePathAssets';

import '../sass/pages/noticias.scss';

import nota1 from '../img/home/notas/nota1.jpg';

class NoticiasDetalle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: []
        }
    }

    componentDidMount() {
        API.get(`noticia/${this.props.match.params.id}`)
        .then(resp => {
            this.setState({
                loading: false,
                data: resp.data
            });
        });
    }

    render(){
        return (
            <div className="noticias">
                <div className="noticias-detalle">
                    <div className="container-fluid w14">
                        <div className="row justify-content-between">
                            <div className="col-12 col-lg-8 mb-5 mb-lg-0 informacion">
                                {
                                    this.state.loading ?
                                    <>
                                        <TextLoading clase={"mb-3 placeholder-text"} height={"450px"} width={"100%"} />
                                        <TextLoading clase={"mb-3 placeholder-text"} height={"30px"} width={"60%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"100%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"100%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"100%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"100%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"60%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"100%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"100%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"100%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"100%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"30%"} />
                                        <TextLoading clase={"mb-3 placeholder-text"} height={"400px"} width={"100%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"100%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"100%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"100%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"100%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"60%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"100%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"100%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"100%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"100%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"30%"} />
                                    </>
                                    :
                                    <>
                                        <img src={BasePathAsset+this.state.data.nota.portada} alt="Portada" className="img-object w-100 mb-4 portada" />
                                        <h3 className="titulo mb-4">{this.state.data.nota.titulo}</h3>
                                        <div dangerouslySetInnerHTML={{ __html: this.state.data.nota.contenido }}/>
                                    </>
                                }
                            </div>
                            <div className="col-12 col-lg-4 col-xl-4 pl-lg-5 extras">
                                <h4 className="subtlo mb-0">Categorias</h4>
                                <hr className="mt-1 mb-4" />
                                <ul className="list-unstyled mb-5 p-0" >
                                    {
                                        this.state.loading ? '' : <li><a href="/noticias">Todos</a></li>
                                    }
                                    {
                                        this.state.loading ?
                                        <>
                                            <li><TextLoading clase={"placeholder-text"} height={"15px"} width={"150px"} /></li>
                                            <li><TextLoading clase={"placeholder-text"} height={"15px"} width={"150px"} /></li>
                                            <li><TextLoading clase={"placeholder-text"} height={"15px"} width={"150px"} /></li>
                                            <li><TextLoading clase={"placeholder-text"} height={"15px"} width={"150px"} /></li>
                                        </>
                                        :
                                        this.state.data.categorias.map((item, key) => {
                                            return(
                                                <li key={`ct${key}`}>
                                                    <a href={`/noticias/${item.id}/${item.urlAmigable}`}>{item.titulo}</a>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                                <h4 className="subtlo mb-0">Notas Recientes</h4>
                                <hr className="mt-1 mb-4" />
                                <div className="row">
                                    {
                                        this.state.loading ?
                                        <>
                                            <CardLoading clase={"col-12 col-md-6 col-lg-12 mb-4 nomax"} />
                                            <CardLoading clase={"col-12 col-md-6 col-lg-12 mb-4 nomax"} />
                                        </>
                                        :
                                        (this.state.data.similares.length !== 0) ?
                                            this.state.data.similares.map((item, key) => {
                                                return(
                                                    <div className="col-12 col-md-6 col-lg-12 mb-4" key={`nt${key}`}>
                                                        <BlogCard
                                                            cover={BasePathAsset+item.portada}
                                                            title={item.titulo}
                                                            text={item.descripcion_corta}
                                                            too={`/noticia/${item.id}/detalle`}
                                                        />
                                                    </div>
                                                );
                                            })
                                        :
                                        ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NoticiasDetalle;