import React from 'react';
import { Link } from 'react-router-dom';

const NuestraEmpresaCard = ({logo, too, clase}) => {
    return(
        <div className={`col-12 col-md-6 col-lg-3 mb-4 text-center ${clase}`}>
            <div className="cont-img mb-3">
                <img src={logo} alt="" className="max-width-100" />
            </div>
            <Link className="btn btn-style-1" to={too}>Conoce más</Link>
        </div>
    )
}

export default NuestraEmpresaCard;