import React, { Component } from 'react';
import BannerBlue from '../components/BannerBlue';

import '../sass/pages/legal.scss';

import bg from '../img/aviso.jpg';

class Aviso extends Component {
    render() {
        return(
            <div className="legal">
                {/* Portada */}
                <BannerBlue
                    logo={false}
                    title={"Aviso de Privacidad"}
                    img={''}
                    description={"En atención a lo previsto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, en adelante la “Ley”, así como su Reglamento, en adelante, nos permitimos manifestar lo siguiente:"}
                    bgimg={bg}
                    small={'banner-small'}
                />

                <div className="legal-informacion">
                    <div className="container">
                        <h3 className="titulo mb-3">1.- SOBRE EL RESPONSABLE.</h3>
                        <p>GRUPO EMPRESARIAL DUMEDICS S. DE R.L. DE C.V, en adelante “AITEM MEXICO”, persona moral con domicilio en la calle veintiuno, número ciento veintiuno, colonia México, código postal noventa y siete mil ciento veinticinco, Mérida, Yucatán, México, es responsable de recabar los datos personales que le sean proporcionados por las personas físicas o morales, en adelante “el Titular”, del uso que se le dé a los mismos y de su protección. </p>
                        <p>El tratamiento de los datos personales del Titular será efectuado de conformidad con los presentes términos y condiciones así como a las disposiciones establecidas por la “Ley” y su Reglamento, por lo que, desde este momento se entiende que el Titular otorga su consentimiento para dicho tratamiento.</p>
                        <h3 className="titulo mb-3 mt-5">2.- ¿PARA QUÉ FINES UTILIZAREMOS SUS DATOS PERSONALES?</h3>
                        <p>La información personal será utilizada para proveer los productos y servicios siguientes:</p>
                        <ul>
                            <li>
                                <p className="mb-1">Comercialización, distribución, equipamiento y acondicionamiento de equipos y productos médicos de alta calidad y tecnología, con la capacidad de reducir tiempos de diagnóstico y tratamiento, así como mejorar la eficiencia en el trabajo profesional. Los equipos a que se hace referencia son los siguientes: Maquet; Detector Vital; Stryker; Exoesqueleto Roki y Roki Wheels, entre otros, los cuales cuentan con las siguientes características:</p>
                                <ul className="mb-0">
                                    <li><p className="mb-1">Maquet: las 3 líneas de Maquet ofrecen todo tipo de equipos e insumos con los cuales podrá habilitar todos sus quirófanos, área de CEYE y terapia intensiva. Los equipos Maquet ofrecen rendimientos inigualables en una amplia variedad de situaciones y con todo tipo de pacientes. </p></li>
                                    <li><p className="mb-1">Detector Vital: vital Detect es un multidetector de signos vitales, siendo uno de los productos más innovadores en el mercado actual de los dispositivos médicos. A partir del dedo índice izquierdo es capaz de registrar 7 signos vitales, el cual agiliza la labor de médicos y personal de enfermería, ahorrando tiempo y haciendo eficiente el trabajo.</p></li>
                                    <li><p className="mb-1">Stryker: herramienta motorizada diseñada para la esternotomía de revisión que se realiza después de una esternotomía primaria, por lo general después de que se haya formado un tejido cicatrizante significativo.</p></li>
                                    <li><p className="mb-1">Exoesqueleto Roki y Roki Wheels: son un exoesqueleto robótico que permite a personas con poca o nula fuerza en sus piernas, levantarse y volver a caminar.</p></li>
                                </ul>
                            </li>
                            <li><p className="mb-1">Comercialización, producción, alquiler, importación y exportación de productos sanitarios, equipos médicos e insumos relacionados con la salud.</p></li>
	                        <li><p className="mb-1">Administración, operación, supervisión, explotación, mantenimiento y vigilancia de todo tipo de clínicas, sanatorios y hospitales. </p></li>
                        </ul>
                        <p>Del mismo modo, los datos personales recabados pueden ser utilizados para las siguientes actividades que no forman parte de los servicios ofertados por “AITEM MEXICO”:</p>
                        <ul>
                            <li>
                                <p className="mb-1">Actividades propias del haber social de “AITEM MEXICO”:</p>
                                <ul>
                                    <li><p className="mb-1">Cumplimiento de obligaciones legales ante las autoridades administrativas, fiscales y judiciales correspondientes. </p></li>
	                                <li><p className="mb-1">Atención de consultas o dudas sobre los servicios y productos ofertados.</p></li>
                                </ul>
                            </li>
                            <li>
                                <p className="mb-1">Actividades accesorias a los servicios y productos ofrecidos por “AITEM MEXICO”:</p>
                                <ul>
                                    <li><p className="mb-1">Notificación de servicios y productos ofertados, eventos patrocinados y noticias relativas a “AITEM MEXICO”.</p></li>
                                </ul>
                            </li>
                        </ul>
                        <p>La negativa para el uso de sus datos personales para estas últimas finalidades no podrá ser un motivo para que le neguemos los servicios y productos que solicite o contrate con nosotros.</p>
                        <h3 className="titulo mb-3 mt-5">3.- ¿QUÉ DATOS PERSONALES RECABAMOS Y UTILIZAMOS?</h3>
                        <p>Para las finalidades antes mencionadas, “AITEM MEXICO” podrá requerir los siguientes datos personales: </p>
                        <ul>
                            <li>
                                <p className="mb-1">Para fines de identificación:</p>
                                <ul>
                                    <li><p className="mb-1">Nombre completo y apellidos.</p></li>
                                    <li><p className="mb-1">Correo electrónico.</p></li>
                                    <li><p className="mb-1">Ciudad de origen.</p></li>
                                    <li><p className="mb-1">Domicilio convencional.</p></li>
                                    <li><p className="mb-1">Fecha de nacimiento.</p></li>
                                    <li><p className="mb-1">Sexo.</p></li>
                                    <li><p className="mb-1">Ocupación o trabajo. </p></li>
                                    <li><p className="mb-1">Nacionalidad.</p></li>
                                    <li><p className="mb-1">Número telefónico fijo y/o de telefonía móvil.</p></li>
                                    <li><p className="mb-1">Identificación oficial. </p></li>
                                    <li><p className="mb-1">Registro Federal de Contribuyentes (RFC).</p></li>
                                    <li><p className="mb-1">Domicilio fiscal.</p></li>
                                    <li><p className="mb-1">Clave Bancaria Estandarizada (CLABE) interbancaria.</p></li>
                                </ul>
                            </li>
                        </ul>
                        <p>Es responsabilidad del Titular de los datos personales el garantizar que los datos facilitados a “AITEM MEXICO” sean veraces y completos, así como comunicar a la empresa cualquier modificación en los mismos a efecto de que se pueda cumplir con la obligación de mantener la información actualizada.</p>
                        <p>La temporalidad del manejo de los datos personales del Titular en todos los casos será sólo por el tiempo razonable y para cumplir con las obligaciones que se deriven con motivo del vínculo profesional que tiene el Titular con “AITEM MEXICO”.</p>
                        <p>La información que sea entregada a “AITEM MEXICO” será debidamente resguardada, conservada y protegida con los medios tecnológicos y físicos adecuados a efecto de que se impida su pérdida, mal uso, alteración, acceso no autorizado y robo. Sólo tendrán acceso a la información aquellas personas estrictamente autorizadas ya sean trabajadores, proveedores de servicios o socios de negocios, quienes han asumido el compromiso de mantener la información bajo un estricto orden de confidencialidad y seguridad.</p>
                        <p>En caso de que los datos personales resguardados sean requeridos por una autoridad de cualquier índole, ya sea por proceso legal, para responder a cualquier reclamo o acciones legales, o para proteger los derechos de “AITEM MEXICO” o sus clientes y el público, estos datos se podrán a su disposición dentro del estricto cumplimiento a la Ley.</p>
                        <p>“AITEM MEXICO” NO recabará datos sensibles tales como:</p>
                        <ul>
                            <li><p className="mb-1">Origen racial o étnico.</p></li>
                            <li><p className="mb-1">Estado de salud.</p></li>
                            <li><p className="mb-1">Información genética.</p></li>
                            <li><p className="mb-1">Creencias religiosas, filosóficas y morales.</p></li>
                            <li><p className="mb-1">Afiliación sindical.</p></li>
                            <li><p className="mb-1">Opiniones políticas.</p></li>
                            <li><p className="mb-1">Preferencia sexual.</p></li>
                        </ul>
                        <p>En general, “AITEM MEXICO” no recaba datos sensibles de ninguna naturaleza.</p>
                        <h3 className="titulo mb-3 mt-5">4.- ¿CÓMO PUEDO EJERCER MIS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN DE DATOS PERSONALES?</h3>
                        <p>Tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada conforme a los principios, deberes y obligaciones previstas en la normativa (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos “ARCO”.</p>
                        <p>Los Titulares de la información tendrán derecho a solicitar el acceso, rectificación, cancelación u oposición del tratamiento de sus datos, mediante solicitud dirigida a la dirección electrónico siguiente: <a href="mailto:info@aitemmexico.com" ref="noreferrer noopener">info@aitemmexico.com</a> en su defecto, mediante escrito dirigido al responsable en el horario comprendido de las 9:00 horas a las 19:00 horas, en días hábiles, en el domicilio señalado en el capítulo primero del presente aviso. </p>
                        <p>“AITEM MEXICO” dará respuesta a las solicitudes del Titular que sean ejercidas por éste previa acreditación de su identidad con identificación oficial (credencial para votar con fotografía, pasaporte, cartilla militar, o licencia de conducir), escaneada en copia simple o, en caso de actuar por representación de un tercero, se deberá de presentar la Escritura Pública o carta poder firmada ante dos testigos. “AITEM MEXICO” se reserva el derecho de solicitar la exhibición física de los originales para cotejar con las copias que son enviadas.</p>
                        <p>Toda solicitud para que sea tramitada deberá contener y acompañar lo siguiente:</p>
                        <ul>
                            <li><p className="mb-1">El nombre del titular y dirección electrónica para comunicarle la respuesta a su solicitud.</p></li>
                            <li><p className="mb-1">Los documentos que acrediten la identidad o, en su caso, la representación legal del titular. La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos. </p></li>
                            <li><p className="mb-1">Cualquier otro elemento o documento que facilite la localización de los datos personales.</p></li>
                        </ul>
                        <p>“AITEM MEXICO” tendrá un plazo de veinte días hábiles, contados desde la fecha en que se recibió la solicitud, o a partir de que el titular solvento el requerimiento de información, para comunicarle al titular si se acepta o no la misma. Los plazos referidos podrán ser ampliados una sola vez por un periodo igual cuando esté justificado. </p>
                        <p>La respuesta a las solicitudes se dará a elección del Titular por medio de correo electrónico, o mediante respuesta escrita, cuando acuda directamente a las oficinas de “AITEM MEXICO” en la dirección señalada a recogerla, pudiéndose reproducir la respuesta en documentos electrónicos, copias simples, o de manera verbal.</p>
                        <p>Los datos de contacto de la persona o departamento que dará trámite a las solicitudes para el ejercicio de los derechos ARCO, así como atender cualquier duda que pudiera tener respecto al tratamiento de su información son los siguientes: Departamento de Administración, al correo electrónico <a href="mailto:info@aitemmexico.com" ref="noreferrer noopener">info@aitemmexico.com</a></p>
                        <p>El ejercicio de los derechos “ARCO” podrá restringirse por razones de seguridad nacional, disposiciones de orden público, seguridad y salud públicas o para proteger los derechos de terceras personas, en los casos y con los alcances previstos en las leyes aplicables en la materia</p>
                        <h3 className="titulo mb-3 mt-5">5.- ¿CÓMO PUEDO LIMITAR EL USO O DIVULGACIÓN DE SU INFORMACIÓN PERSONAL?</h3>
                        <p>Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos el siguiente medio:</p>
                        <ul>
                            <li><p className="mb-1">Su inscripción en el Registro Público para Evitar Publicidad, que está a cargo de la Procuraduría Federal del Consumidor, con la finalidad de que sus datos personales no sean utilizados para recibir publicidad o promociones de empresas de bienes o servicios. Para mayor información sobre este registro, usted puede consultar el portal de Internet de la PROFECO, o bien ponerse en contacto directo con ésta.</p></li>
                        </ul>
                        <h3 className="titulo mb-3 mt-5">6.- ¿CÓMO PUEDO REVOCAR MI CONSENTIMIENTO PARA EL TRATAMIENTO DE MIS DATOS PERSONALES?</h3>
                        <p>Los Titulares de la información tendrán derecho a revocar el consentimiento para el tratamiento de sus datos en cualquier momento, para lo cual deberán de presentar su solicitud en los términos referidos en el apartado que precede.</p>
                        <h3 className="titulo mb-3 mt-5">7.- ¿CÓMO PUEDO CONOCER LOS CAMBIOS A ESTE AVISO DE PRIVACIDAD?</h3>
                        <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.</p>
                        <p>Nos reservamos el derecho de cambiar este aviso de privacidad en cualquier momento. En caso de que exista algún cambio sustancial o relevante a este aviso de privacidad, las modificaciones o actualizaciones que correspondan estarán disponibles en nuestro portal de internet www.aitemmexico.com o bien, mediante cualquier otro medio que resulte conveniente y adecuado para su mejor información.</p>
                        <h3 className="titulo mb-3 mt-5">8.- OTRAS NORMATIVIDADES APLICABLES.</h3>
                        <p>Las leyes y regulaciones de otros países pueden imponer diferentes requerimientos para la protección de la información en general y de los datos personales que se recolectan. “AITEM MEXICO” se encuentra ubicada en México y todos los asuntos que le conciernen son regidos por las leyes de México. Si usted está ubicado en algún otro país distinto a México y nos contacta, por favor tome en cuenta que cualquier información que usted nos provea será enviada a México, y al momento de ingresar su información usted autoriza este envío y la aceptación del presente aviso de privacidad.</p>
                        <h3 className="titulo mb-3 mt-5">9.- SOBRE EL INSTITUTO NACIONAL DE TRANSPARENCIA Y ACCESO A LA INFORMACIÓN Y PROTECCIÓN DE DATOS PERSONALES (INAI).</h3>
                        <p>Si usted considera que su derecho a la protección de sus datos personales ha sido lesionado por alguna conducta u omisión de nuestra parte, o presume alguna violación a las disposiciones previstas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento y demás ordenamientos aplicables, podrá interponer su inconformidad o su solicitud de protección de derechos  ante el INAI.</p>
                        <p>Otorgo mi consentimiento para que mis datos personales sean tratados conforme a lo señalado en el presente aviso de privacidad, así como para que sean utilizados en actividades accesorias a los servicios ofrecidos por “AITEM MEXICO”</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Aviso;