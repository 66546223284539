import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

class SidebarMenu extends Component {
    render() {
        const handleClass = this.props.open ? 'sidebarMenu active d-lg-none' : 'sidebarMenu d-lg-none';

        return (
            <div className={handleClass}>
                <ul className="list-unstyled m-0 p-0">
                    <li className="mb-4"><NavLink onClick={this.props.handleClose} to="/" exact activeClassName="active">Inicio</NavLink></li>
                    <li className="mb-4"><NavLink onClick={this.props.handleClose} to="/home#quienes-somos" exact activeClassName="not-active">¿Quiénes Somos?</NavLink></li>
                    <li className="mb-4"><NavLink onClick={this.props.handleClose} to="/home#nuestras-empresas" exact activeClassName="not-active">Nuestras empresas</NavLink></li>
                    <li className="mb-4"><NavLink to="#" onClick={this.toggleSubmenu} activeClassName="not-active">Productos y Soluciones</NavLink></li>
                    <li className="mb-4"><NavLink onClick={this.props.handleClose} to="/noticias" activeClassName="active">Noticias</NavLink></li>
                    <li className="mb-4"><NavLink onClick={this.props.handleClose} to="/contacto" activeClassName="active">Contacto</NavLink></li>
                    {/* <li className="idiomas mt-5">
                        <span className="idiomas">Español</span>
                        <span className="idiomas px-2">|</span>
                        <span className="idiomas">Ingles</span>
                    </li> */}
                </ul>
            </div>
        );
    }
}

export default SidebarMenu;