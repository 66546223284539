import axios from 'axios';

/**
 * AXIOS - https://github.com/axios/axios
 * Aqui decimos cual sera la URL de nuestra API de la que vamos a traer toda la info
 * en este caso le indicamos a Axios que nuestra base para las URL de GET, POST, DELETE, etc sera la siguiente
 */

export default axios.create({
    //baseURL: 'https://admin.dumedics.locker.agency/api/'
    // baseURL: 'http://127.0.0.1:8000/api/'
     baseURL: 'https://admin.grupodumedics.com.mx/api/'
});