import React, { Component } from 'react';
import BannerBlue from '../components/BannerBlue';

import logo from '../img/aitem/logo.png';
import bgbanner from '../img/aitem/portada_aitem.jpg';
import imagen from '../img/aitem/imagen.jpg';
import icon from '../img/aitem/icon.svg';
import pdf_aitem from '../img/aitem/AITEM2022_catalogo.pdf';
class SomosAitem extends Component {
    render() {
        function NuestrosServicios(props) {
            return(
                <div className="card card-nuestros-servicios text-center h-100">
                    <img src={props.icon} alt="Icono" style={{width: "70px"}} className="mx-auto" />
                    <div className="card-body p-lg-5">
                        <h4 className="titulo mb-4">{props.title}</h4>
                        <p>{props.text}</p>
                    </div>
                </div>
            );
        }

        return (
            <div className="somos">
                {/* Portada azul */}
                <BannerBlue
                    logo={true}
                    title={"AITEM"}
                    img={logo}
                    description={"Somos una sub empresa de Grupo Empresarial Dumedics, estamos dedicados a la comercialización de equipos y dispositivos medicos con los estandares mas altos en tecnologia e innovacion, a travez de los cuales medicos y personal de salud pueden dar diagnosticos certeros y tratamientos efectivos de manera personalizada a cada paciente."}
                    bgimg={bgbanner}
                />

                {/* Mision y Vision */}
                <div className="somos-section-2 pt-100">
                    <div className="container-fluid w12">
                        <div className="row align-items-center justify-content-around">
                            <div className="col-12 col-md-6 col-lg-5 mb-4 mb-md-0">
                                <h3 className="titulo mb-3">Misión</h3>
                                <p>Otorgar herramientas de monitoreo, diagnóstico y tratamiento exitosos a través de nuestros productos a millones de pacientes en todos los estados de la república.</p>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5">
                                <h3 className="titulo mb-3">Visión</h3>
                                <p>Nuestra visión esta enfocada hacia la evolución digital y tecnológica en los servicios de salud, con la que se podrá mejorar la calidad de los servicios sanitarios en cada rincón de Latinoamérica.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Nuestros servicios */}
                <div className="somos-section-2">
                    <div className="container-fluid w16">
                        <h3 className="titulo mb-5 text-center">Nuestros Servicios</h3>
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                                <NuestrosServicios icon={icon} title={"Comercialización"} text={"Somos una empresa con capacidad de importar y comercializar equipos y dispositivos médicos desde diversas regiones del mundo y con los más altos estándares de calidad y tecnología."}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                                <NuestrosServicios icon={icon} title={"Venta"} text={"Estamos enfocados en la venta y distribución de equipos y dispositivos médicos, nuestro principal cliente son hospitales y clínicas con una vision hacia la evolución tecnológica y digital de los servicios de salud. "}/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                                <NuestrosServicios icon={icon} title={"Equipamiento"} text={"Damos servicio de equipamiento, puesta en marcha y mantenimiento de nuestros equipos para cualquier area de su clínica u hospital, ya sea area de terapia y quirófanos hasta hospitalización y urgencias."}/>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Catalogo */}
                <div className="somos-section-3">
                    <div className="container-fluid w16">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-md-6 col-lg-5 col-xl-4 py-5">
                                <h3 className="titulo text-white mb-5">Conoce nuestro catálogo</h3>
                                <p className="text-white">Consulte nuestras 5 lineas de productos, desde mobiliario hasta soporte vital avanzado, con los cuales podra equipar su clinica, hospital o consultorio y dar a sus pacientes la confianza y atencion que se merecen.</p>
                                <a className="btn btn-style-3" href={pdf_aitem} download>Descargar catálogo</a>
                            </div>
                            <div className="col-12 col-md-6 px-0">
                                <img src={imagen} alt="imagen" className="h-100 img-object w-100" style={{minHeight: "450px"}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SomosAitem;