import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from '../img/logo-footer.svg';
import { Link } from 'react-router-dom';

class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="container-fluid w16">
                    <div className="row text-center text-lg-left align-items-sm-center align-items-lg-start justify-content-between">
                        <div className="col-12 col-sm-6 col-lg-4 mb-4">
                            <img src={logo} alt="Grupo Dumedics" className="max-width-100 mb-2" />
                            <p className="mb-0">© 2021. Grupo Empresarial Dumedics S. de R.L. de C.V.</p>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 mb-4">
                            <h4 className="tlo mb-3">Datos de Contacto</h4>
                            <p className="mb-1">contacto@grupodumedics.com.mx</p>
                            <p className="mb-1">(999) 185 0970</p>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 col-xl-2 mb-4">
                            <h4 className="tlo mb-3">Legal</h4>
                            <p className="mb-1"><Link to="/aviso-privacidad">Aviso de Privacidad</Link></p>
                            <p className="mb-1"></p>
                            <p className="mb-1">Términos y Condiciones</p>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-2 text-lg-right">
                        <a href="https://twitter.com/AITEMMexico" target="_blank" rel="noreferrer">
                         <FontAwesomeIcon className="text-white mr-3 mr-xl-4" icon={['fab', 'twitter-square']} size="2x" />
                        </a>
                        <a href="https://www.facebook.com/aitemcomercializadora/" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className="text-white mr-3 mr-xl-4" icon={['fab', 'facebook-square']} size="2x" />
                            </a>   
                            <a href="https://www.instagram.com/aitemmexico/" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className="text-white" icon={['fab', 'instagram-square']} size="2x" />
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;