import React, { Component } from 'react';
import {animationL} from './LoadingAnimation';

import '../sass/pages/loading.scss';

/**
 * Componente que hace de placeholder para los links dinamicos en el HEader
 */
class LinkLoading extends Component{
    htmlLinkLoading() {
        return <li className={`placeholder-opacity mb-3 ${this.props.clase}`}></li>
    }

    componentDidMount() {
        animationL();
    }

    render() {
        return(
            <>
                {this.htmlLinkLoading()}
                {this.htmlLinkLoading()}
                {this.htmlLinkLoading()}
                {this.htmlLinkLoading()}
            </>
        )
    }
}

export default LinkLoading;