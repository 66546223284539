import React, {Component} from 'react';
import { NavLink } from 'react-router-dom';
import HeaderProdctosCategorias from './HeaderProductosCategorias';
import SidebarMenu from './SidebarMenu';
/**
 * GSAP - https://greensock.com/gsap/
 * Animacion con GSAP
 */
import { gsap } from 'gsap';

import logo from '../img/logo-header.svg';

/**
 * El header maneja un menu dinamico y contiene props locales que son variables locales y globales dentro del componente no fuera de él
 * 
 */
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            open: false
        }
        this.toggleSubmenu = this.toggleSubmenu.bind(this);
        this.toggleMenuMovil = this.toggleMenuMovil.bind(this);
        this.closeAll = this.closeAll.bind(this);
    }

    /**
     * Funcion que abre o cierra el submenu dinamico
     */
    toggleSubmenu = () => {
        this.setState({show: !this.state.show});
    }

    /**
     * Funcion que abre o cierra el menu en movil
     */
    toggleMenuMovil = () => {
        this.setState({open: !this.state.open});

        // Animacion que muestra el sidebar en movil
        if(!this.state.open) {
            gsap.to('.sidebarMenu li', {
                duration: 0.6,
                delay: 0.2,
                opacity: 1,
                stagger: 0.1,
                x: 10,
                ease: 'power1.inOut'
            });
        } else {
            gsap.to('.sidebarMenu li', {
                duration: 0,
                delay: 0.1,
                opacity: 0,
                stagger: 0.1,
                x: -10,
                ease: 'power1.inOut'
            });
        }
    }

    /**
     * Funcion que cierra todos los tipos de menu y submenus
     * Esto se hace porque en el enrutamiento no volvemos a pintar el Header entonces quedan abiertos
     * si volvieramos de igual manera a pintar el header se cerrarian automaticamente pero tambien se volverian a hacer
     * las peticiones fetch y eso es hacerlo cada vez que se cambia de vista
     */
    closeAll = () => {
        this.setState({open: false, show: false});
        gsap.to('.sidebarMenu li', {
            duration: 0,
            delay: 0.1,
            opacity: 0,
            stagger: 0.1,
            x: -10,
            ease: 'power1.inOut'
        });
    }


    render() {
        return(
            <header>
                <div className="nav-menu container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-6 col-md-4 col-lg-3 col-xl-3">
                            <div className="logo">
                                <img src={logo} alt="Grupo Dumedics" className="max-width-100" />
                            </div>
                        </div>
                        <div className="col-6 col-md-8 col-lg-8 col-xl-8 d-none d-lg-block">
                            <ul className="list-unstyled m-0 p-0 d-flex align-items-center justify-content-between">
                                <li><NavLink onClick={this.closeAll} to="/" exact activeClassName="active">Inicio</NavLink></li>
                                <li><NavLink onClick={this.closeAll} to="/home#quienes-somos" exact activeClassName="not-active">¿Quiénes Somos?</NavLink></li>
                                <li><NavLink onClick={this.closeAll} to="/home#nuestras-empresas" exact activeClassName="not-active">Nuestras empresas</NavLink></li>
                                <li><NavLink to="#" onClick={this.toggleSubmenu} activeClassName="not-active">Productos y Soluciones</NavLink></li>
                                <li><NavLink onClick={this.closeAll} to="/noticias" activeClassName="active">Noticias</NavLink></li>
                                <li><NavLink onClick={this.closeAll} to="/contacto" activeClassName="active">Contacto</NavLink></li>
                                {/* <li className="idiomas">
                                    <span className="idiomas">Español</span>
                                    <span className="idiomas px-2">|</span>
                                    <span className="idiomas">Ingles</span>
                                </li> */}
                            </ul>
                        </div>
                        <div className="col-6 col-md-8 text-right d-lg-none">
                            <div className={`menu menu-2 d-inline-block ${this.state.open ? 'active' : ''}`} onClick={this.toggleMenuMovil}>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    /**
                     * Algo muy interesante en React es que podemos enviar funciones entre componentes asi las funciones que declaramos aqui
                     * las podemos usar en otros componentes y el resultado afecta a nuestros props locales de este componente ya que la funcion
                     * se envia desde aqui
                     * closeAll se envia a los componentes de HeaderProdctosCategorias y SidebarMenu ademas del props
                     */
                }
                <HeaderProdctosCategorias show={this.state.show} handleClose={this.closeAll} />
                <SidebarMenu open={this.state.open} handleMenu={this.toggleMenuMovil} handleClose={this.closeAll} />
            </header>
        )
    }
}

export default Header;