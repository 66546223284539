import React, { Component } from 'react';
import CardProducto from '../components/CardProducto';
import Galeria from '../components/Galeria';
import ModalProducto from '../components/ModalProducto';
import API from '../components/Api';
import TextLoading from '../components/TextLoading';
import CardLoading from '../components/CardLoading';
import BasePathAsset from '../components/BasePathAssets';

import '../sass/pages/productos.scss';

import imgLoading from '../img/spin-loading.gif';

class ProductoDetalle extends Component {
    constructor(props){
        super(props);
        this.state = {
            fakes: [ imgLoading, imgLoading, imgLoading, imgLoading, imgLoading, imgLoading, imgLoading ],
            data: [],
            loading: true
        }
    }

    componentDidMount() {
        API.get(`producto/${this.props.match.params.id}`)
        .then(res => {
            let data = res.data;
            console.log(data);
            this.setState({
                data: data,
                loading: false
            })
        })
    }

    render() {
        return (
            <div className="productos">
                {/* Detalle del producto */}
                <div className="productos-detalle">
                    <div className="container-fluid w15 after-white">
                        <div className="row" style={{position: "relative", zIndex: "2"}}>
                            <div className="col-12 col-md-6 col-xl-7 ">
                                <Galeria gallery={this.state.loading ? this.state.fakes : this.state.data.galeria} loading={this.state.loading} portada={this.state.loading ? '': this.state.data.producto.cover} />
                            </div>
                            <div className="col-12 col-md-6 col-xl-5">
                                <h4 className="mb-0">{this.state.loading ? <TextLoading clase={"mb-2 placeholder-text"} height={"30px"} width={"80%"} /> : this.state.data.producto.titulo}</h4>
                                <h3 className="mb-5">{this.state.loading ? <TextLoading clase={"mb-5 placeholder-text"} height={"30px"} width={"50%"} /> : '$ '+this.state.data.producto.precio}</h3>

                                {this.state.loading ? 
                                    <>
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"100%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"100%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"100%"} />
                                        <TextLoading clase={"mb-4 placeholder-text"} height={"15px"} width={"50%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"100%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"100%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"100%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"100%"} />
                                        <TextLoading clase={"mb-2 placeholder-text"} height={"15px"} width={"30%"} />
                                    </>
                                    :
                                    <div dangerouslySetInnerHTML={{ __html: this.state.data.producto.descripcion }}/>
                                }
                                <ModalProducto/>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Productos similares */}
                <div className="productos-similares">
                    <h3 className="titulo text-center mb-5">Productos similares</h3>

                    <div className="container">
                        <div className="row justify-content-center">
                            {
                                this.state.loading ?
                                <>
                                    <CardLoading clase={"col-12 col-sm-6 col-md-4 col-lg-3 mb-4 mb-md-0"} />
                                    <CardLoading clase={"col-12 col-sm-6 col-md-4 col-lg-3 mb-4 mb-md-0"} />
                                    <CardLoading clase={"col-12 col-sm-6 col-md-4 col-lg-3 mb-4 mb-md-0"} />
                                </>
                                :
                                this.state.data.similares.map((item, key) => {
                                    return(
                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4 mb-md-0">
                                            <CardProducto
                                                cover={BasePathAsset+item.cover}
                                                title={item.titulo}
                                                price={item.precio}
                                                too={"/producto/"+item.id+"/detalle"}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProductoDetalle;