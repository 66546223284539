import React, { Component } from 'react';
import BannerBlue from '../components/BannerBlue';

import '../sass/pages/nuestras_empresas.scss';

import logo from '../img/wfb/logo.svg';
import bgbanner from '../img/wfb/wfb_cover.jpg';
import cofepris from '../img/wfb/cofepris.jpg';
import fda from '../img/wfb/fda.jpg';
import imagen from '../img/wfb/wfb_catalogo.jpg';
import pdf_item from '../img/wfb/CatalogoWFB2021.pdf';

class SomosWfb extends Component {
    render(){
        return(
            <div className="somos">
                {/* Portada azul */}
                <BannerBlue
                    logo={true}
                    title={"WFB"}
                    img={logo}
                    description={"Nuestra marca de desechables WFB es capaz de cubrir todas las necesidades de su personal, tanto de quirofano y CEYE como area de terapia y COVID-19, Adaptandose a sus requerimientos desde composicion o tipo de tela hasta tamaño o numero de bolsas en las prendas."}
                    bgimg={bgbanner}
                />

                {/* Certificados */}
                <div className="somos-section-2 pt-100">
                    <div className="container-fluid w14">
                        <div className="row align-items-center justify-content-end">
                            <div className="col-12 col-md-6 col-lg-5 mb-4 mb-md-0">
                                <h3 className="titulo mb-3">Certificados</h3>
                                <p>Nuestra marca esta respaldada con certificados de COFEPRIS y FDA lo cual nos ha permitido ampliar nuestros horizontes, participando tambien como exportadores y proveedores de grandes hospitales y clinicas del extranjero.</p>
                            </div>
                            <div className="col-12 col-md-6 d-flex align-items-center">
                                <img src={cofepris} alt="Cofepris" className="col-6" />
                                <img src={fda} alt="FDA" className="col-6" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Catalogo */}
                <div className="somos-section-4">
                    <div className="container-fluid w12">
                        <div className="row bg-gris">
                            <div className="col-12 col-md-6 pr-lg-4 d-flex align-items-start justify-content-center py-5 pl-lg-5" style={{flexDirection: "column"}}>
                                <h3 className="titulo mb-4">Conoce nuestro catálogo</h3>
                                <p className="mb-4">Descargue nuestro catálogo y conozca la amplia variedad de productos que manejamos asi como las diversas opciones que podemos ofrecerle para personalizar sus prendas.</p>
                                <a className="btn btn-style-2" href={pdf_item} download>Descargar catálogo</a>
                            </div>
                            <div className="col-12 col-md-6 px-0">
                                <img src={imagen} alt="Imagen" className="h-100 w-100 img-object" style={{minHeight: "300px"}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SomosWfb;