import React, { Component } from 'react';
import NuestraEmpresaCard from './NuestraEmpresaCard';

import logo_1 from '../img/home/nuestras_empresas/logo_dumedics.svg';
import logo_2 from '../img/home/nuestras_empresas/marca_aitem.png';
import logo_3 from '../img/home/nuestras_empresas/wfb_logo.svg';
import logo_4 from '../img/home/nuestras_empresas/marca_medder.png';

class NuestrasEmpresas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardNone: props.clase
        }
    }

    activarClase(trigger) {
        if (trigger === this.state.cardNone) {
            return 'd-none';
        }

        return '';
    }

    render() {

        return (
            <div className="nuestras-empresas" id={"nuestras-empresas"}>
                <div className="container-fluid">
                    <h3 className="titulo text-center mb-5 pb-3">Nuestras Empresas</h3>

                    <div className="row justify-content-center">
                        <NuestraEmpresaCard clase={this.activarClase('dumedics')} logo={logo_1} too={"/nuestras-empresas/dumedics"} />
                        <NuestraEmpresaCard clase={this.activarClase('aitem')} logo={logo_2} too={"/nuestras-empresas/aitem"} />
                        <NuestraEmpresaCard clase={this.activarClase('wfb')} logo={logo_3} too={"/nuestras-empresas/wfb"} />
                        <NuestraEmpresaCard clase={this.activarClase('medder')} logo={logo_4} too={"/nuestras-empresas/medder"} />
                    </div>
                </div>
            </div>
        )
    }
}

export default NuestrasEmpresas;