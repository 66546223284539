import React, { Component } from 'react';
import BannerBlue from '../components/BannerBlue';
import CardLoading from '../components/CardLoading';
import ListProductos from '../components/ListProductos';
import SinResultados from '../components/SinResultados';
import API from '../components/Api';

import '../sass/pages/productos.scss';

import bgbanner from '../img/productos/productos_cover.jpg';

class Productos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productos: [],
            informacion: [],
            loading: true,
            pagina: 1,
            totalDatos: 0
        }
        this.masProductos = this.masProductos.bind(this);
    }

    obtenerDatos() {
        let id = this.props.match.params.id,
            subid = this.props.match.params.subid,
            data = {};

        this.setState({pagina: this.state.pagina + 1});
        data = {page: this.state.pagina};

        API.get('productos/'+id+'/'+subid, {params: data})
        .then(res => {
            let info = res.data;
            let resultados = info.productos;

            this.setState({
                productos: [...this.state.productos, ...resultados.data],
                totalDatos: resultados.data.length,
                informacion: info.subcategoria,
                loading: false
            });
        })
    }

    masProductos = () => {
        this.obtenerDatos();
    }

    componentDidMount() {
        this.obtenerDatos();
    }

    render () {
        return(
            <div className="productos">
                {/* Portada azul */}
                <BannerBlue
                    logo={false}
                    title={this.state.informacion.titulo}
                    img={''}
                    description={this.state.informacion.descripcion}
                    bgimg={bgbanner}
                />

                <div className="productos-listado">
                    <div className="container-fluid w15">
                        <h3 className="titulo">Productos</h3>
                        <hr />
                        {
                            this.state.loading ? 
                                <div className="row">
                                    <CardLoading clase={"col-12 col-sm-6 col-md-4 col-lg-3 mb-4"} />
                                    <CardLoading clase={"col-12 col-sm-6 col-md-4 col-lg-3 mb-4"} />
                                    <CardLoading clase={"col-12 col-sm-6 col-md-4 col-lg-3 mb-4"} />
                                    <CardLoading clase={"col-12 col-sm-6 col-md-4 col-lg-3 mb-4"} />
                                    <CardLoading clase={"col-12 col-sm-6 col-md-4 col-lg-3 mb-4"} />
                                    <CardLoading clase={"col-12 col-sm-6 col-md-4 col-lg-3 mb-4"} />
                                    <CardLoading clase={"col-12 col-sm-6 col-md-4 col-lg-3 mb-4"} />
                                    <CardLoading clase={"col-12 col-sm-6 col-md-4 col-lg-3 mb-4"} />
                                </div>
                                :
                                (this.state.productos.length === 0) ? <SinResultados /> : <ListProductos listado={this.state.productos} />
                        }

                        {this.state.totalDatos === 8 ?
                            <div className="text-center">
                                <button className="btn btn-style-2 my-5" style={{maxWidth: "240px"}} onClick={this.masProductos}>Ver más</button>
                            </div> : ""
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Productos;