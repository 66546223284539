import React, { Component } from 'react';
import Modal from './Modal';

class ModalProducto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    /**
     * Funcionalidad de Modal
     */
    showModal = () => {
        this.setState({show: true});
    }

    hideModal = () => {
        this.setState({show: false});
    }

    render() {
        return(
            <main>
                <button className="btn btn-style-2 mt-3" onClick={this.showModal}>Me interesa</button>

                <Modal show={this.state.show} handleClose={this.hideModal}>
                    <div className="modal-header flex-column text-center pr-md-5 pl-md-5 border-0">
                        <h5 className="modal-title w-100">¿Te interesa este producto?</h5>
                        <p className="mb-0">Favor de llenar los datos siguientes y nos pondremos en contacto lo más pronto</p>
                    </div>
                    <div className="modal-body pr-md-5 pl-md-5 pt-4">
                        <form>
                            <input type="text" name="nombre" id="nombre" placeholder="Nombre" className="form-control mb-3" />
                            <input type="text" name="apellido" id="apellido" placeholder="Apellido" className="form-control mb-3" />
                            <input type="text" name="telefono" id="telefono" placeholder="Teléfono" className="form-control mb-3" />
                            <input type="text" name="correo" id="correo" placeholder="Correo" className="form-control mb-3" />
                            <textarea rows="5" cols="30" placeholder="Mensaje" className="form-control mb-4" name="mensaje" id="mensaje"></textarea>
                            <div className="form-group text-center mb-4">
                                <input className="custom-checkbox" type="checkbox" id="chkbx_2" value="1" />
                                <label>
                                    <span></span> Acepto Términos y Condiciones
                                </label>
                            </div>
                            <div className="text-center">
                                <button className="btn btn-style-2">Enviar</button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </main>
        )
    }
}

export default ModalProducto;