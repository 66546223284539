import React, { Component } from 'react';

/**
 * Tabs
 * Este componente es espacial, literalmente es un tabs, {children} es un props de React que hara que podamos implementar un componente
 * en ese espacio un ejemplo basico seria
 * <Tab>
 *      Aqui va la informacion (botones y cards de info)
 * </Tab>
 * En React se pueden usar componentes de Bootstrap usando la libreria React Bootstrap - https://react-bootstrap.github.io/
 * En este proyecto ya se usa bootstrap para no meter dos veces la libreria se decidio hacer nuestro propio tabs usando 
 * las clases que trae bootstrap pero la funcionbalidad la hacemos nosotros, hacemos el codigo mas limpio.
 * 
 * Un ejemplo de como se usa mejor esta en Contacto.js
 */
class Tab extends Component {
    onClick = () => {
        const { label, onClick } = this.props;
        onClick(label);
    }

    render() {
        const {
            onClick,
            props: {
                activeTab,
                label,
            },
        } = this;

        let className = 'tab-list-item';

        if (activeTab === label) {
            className += ' tab-list-active';
        }

        return (
            <li
                className={className}
                onClick={onClick}
            >
                {label}
            </li>
        );
    }
}

export default Tab;