import React, {Component} from 'react';
import '../sass/pages/loading.scss';
import {animationLS} from './LoadingAnimation';

/**
 * Este componente es el card placeholder cuando los estados de cargando son verdaderos
 */
class CardLoading extends Component {
    htmlCardLoading() {
        return <div className={this.props.clase}>
                    <div className="card card-loading h-100">
                        <div className="placeholder-img"></div>
                        <div className="card-body text-center">
                            <div className="placeholder-opacity-scale placeholder-titulo mb-2"></div>
                            <div className="placeholder-opacity-scale placeholder-precio mb-4"></div>
                            <div className="placeholder-opacity-scale placeholder-link"></div>
                        </div>
                    </div>
                </div>;
    }

    componentDidMount() {
        /**
         * Cuando el componente esta cargado en el DOM ejecuta una animacion para los cards
         */
        animationLS()
    }

    render() {
        return(
            <>
                {this.htmlCardLoading()}
            </>
        );
    }
}

export default CardLoading;