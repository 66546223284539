import React, { Component } from 'react';
import API from '../components/Api';
import axios from 'axios';
import Swal from 'sweetalert2'

/**
 * En este componente hay props heredados desde un componente Padre
 * bg - portada
 * empresa - empresa del formulario
 */
class FormContacto extends Component {
    constructor(props) {
        super(props);

        this.state = {
            empresa: props.empresa,
            nombre: "",
            apellido: "",
            telefono: "",
            correo: "",
            mensaje: "",
            pais: "",
            industria: "",

            verificado: false,
            enviado: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }
    
    handleSubmit(ev) {
        ev.preventDefault();
        let form = document.querySelector("#formContacto");
        console.log(this.state);

        if (form.checkValidity() === false) {
            //Campos invalidos
            ev.preventDefault();
            ev.stopPropagation();
            console.log(form.checkValidity());
            console.log('If');
        } else {
            console.log('else');
            //TODO: Verificar captcha
            //Hacemos submit jeje
           
                this.setState({...this.state, sending: true});

                //console.log(process.env.REACT_APP_API_URL);
                API.post("contacto/send", null, {params: this.state})
                .then(res => {
                    console.log(res.status);
                    if((res.status === 200) && (res.data.success)){
                        // Mostramos mensaje enviado
                        Swal.fire(
                            'Operación exitosa',
                            'Mensaje enviado',
                            'success'
                        )
                    }else{
                        Swal.fire(
                            'Oops!',
                            'Lo sentimos, algo salió mal',
                            'error'
                        )
                    }
                    this.setState({...this.state, sending: false});
                })
                .catch(err => {
                    console.log(err);
                    Swal.fire(
                        'Oops!',
                        'Lo sentimos, algo salió mal',
                        'error'
                    )
                    this.setState({...this.state, sending: false});
                });
            
        }

        form.classList.add('was-validated');
    }

    verifyCallback (response) {
        this.setState({...this.state, verificado: true});
    }

    showButton(){
        if(this.state.sending){
            return <button type="submit" className="btn btn-style-3 disabled" style={{PointerEvent: "none"}}>Enviando....</button>
        }else{
            return <button type="submit" className="btn btn-style-3" onClick={this.handleSubmit}>Enviar</button>
        }
    }
    render() {
        return(
            <div className="contacto-form" style={{backgroundImage: `url(${this.props.bg})`}}>
                <div className="row justify-content-center no-gutters">
                    <form className="col-12 col-md-10 col-lg-8" id="formContacto">
                        <div className="row no-gutters">
                            <div className="col-12 col-md-6 px-3">
                                <input type="hidden" name="empresa" id="empresa" value={this.props.empresa} defaultValue={this.props.empresa} ref={this.input}   />
                                <input type="text" name="nombre" id="nombre" placeholder="Nombre" className="form-control mb-4" onChange={(el) => {this.setState({...this.state, nombre: el.target.value})}} required/>
                                <input type="text" name="apellido" id="apellido" placeholder="Apellido" className="form-control mb-4" onChange={(el) => {this.setState({...this.state, apellido: el.target.value})}} required/>
                                <input type="text" name="telefono" id="telefono" placeholder="Teléfono" className="form-control mb-4" onChange={(el) => {this.setState({...this.state, telefono: el.target.value})}} />
                                <input type="email" name="correo" id="correo" placeholder="Correo" className="form-control mb-4" onChange={(el) => {this.setState({...this.state, correo: el.target.value})}} required/>
                                <select name="pais" id="pais" className="form-control mb-4" value={this.state.value} onChange={(el) => {this.setState({...this.state, pais: el.target.value})}} >
                                    <option value="NA" >País</option>
                                    <option value="USA">Estados Unidos</option>
                                    <option value="MX">Mexico</option>
                                </select>
                            </div>
                            <div className="col-12 col-md-6 px-3">
                                <input type="text" name="empresa" id="empresa" placeholder="Empresa" className="form-control mb-4" />
                                <select name="industria" id="industria" className="form-control mb-4" value={this.state.value} onChange={(el) => {this.setState({...this.state, industria: el.target.value})}}  >
                                    <option value="NA" >Industria</option>
                                    <option value="Farmaceutica" >Farmaceutica</option>
                                    <option value="Hospitalaria" >Hospitalaria</option>
                                </select>
                                <textarea name="mensaje" id="mensaje" placeholder="Mensaje" className="form-control mb-4" cols="30" rows="7" onChange={(el) => {this.setState({...this.state, mensaje: el.target.value})}}></textarea>
                                <div className="form-group text-center mb-4">
                                    <input className="custom-checkbox " type="checkbox" id="chkbx_2" value="1" />
                                    <label for="chkbx_2" className="text-white">
                                        <span></span> Acepto Términos y Condiciones
                                    </label>
                                </div>
                                <div className="text-center">
                                {this.showButton()} 
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default FormContacto;